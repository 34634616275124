<span mat-dialog-title
  ><div class="title-div" *ngIf="data.title">
    {{ removeLastComma(data.title) }}
    <button
      *ngIf="data.headerButton"
      mat-icon-button
      matTooltip="Search observations associated to this proposal"
      class="button-icon-table-esa"
      (click)="setHeaderMessage()"
    >
      <mat-icon fontSet="material-icons-outlined">search</mat-icon>
    </button>
  </div>
  <div class="subtitle-div" *ngIf="data.subtitle">{{ removeLastComma(data.subtitle) }}</div>
  <div class="subtitle-div" *ngIf="data.footer">{{ removeLastComma(data.footer) }}</div>
  <div class="subtitle-div" *ngIf="data.doi">
    <a target="_blank" [href]="getDOIUrl()" class="proposal-link">DOI: {{ data.doi }}</a>
  </div>
  <button mat-icon-button [autofocus]="false" class="close-button close-button-card" (click)="close()">
    <mat-icon color="warn">close</mat-icon>
  </button></span
>

<mat-dialog-content class="proposal-table">
  <div>
    <table aria-describedby="table_dialog_desc" bordered="true" hover="true" class="fixTableHead table table-bordered">
      <thead>
        <tr>
          <th *ngFor="let head of data.tableHeaders" scope="col">{{ head }}</th>
        </tr>
      </thead>
      <tbody style="height: 400px !important">
        <tr *ngFor="let element of data.publications">
          <td *ngFor="let el of element | slice: data.tableStartIndex : element.length - 1; let i = index">
            <div *ngIf="i === 0 && data.searchButton">
              {{ removeLastComma(el) }}
              <button
                mat-button
                matTooltip="Search observations associated to this publication"
                (click)="setDialogMessage(element[2], element[1])"
              >
                <mat-icon>search</mat-icon>
              </button>
            </div>
            <div *ngIf="i !== 0 || !data.searchButton">{{ removeLastComma(el) }}</div>
          </td>
          <td *ngIf="data.hyperlink">
            <a target="_blank" [href]="data.url + element[element.length - 1]"> {{ element[element.length - 1] }} </a>
          </td>
          <td *ngIf="!data.hyperlink">{{ element[element.length - 1] }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</mat-dialog-content>
