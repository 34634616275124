import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClipboardService } from 'ngx-clipboard';

export interface DialogData {
  header: string;
  body: any;
}

@Component({
  template: `
    <mat-card appearance="outlined">
      <mat-card-header>
        <strong>{{ data.header }}</strong>
        <button mat-button (click)="copyQuery()" matTooltip="Copy to clipboard">
          <mat-icon fontSet="material-icons-outlined">clipboard</mat-icon>
        </button></mat-card-header
      >
      <mat-card-content>{{ data.body }} </mat-card-content>
    </mat-card>
  `,
  styleUrls: ['./dialog-common.scss']
})
export class InformationDialogComponent {
  constructor(
    protected ref: MatDialogRef<InformationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private clipboardApi: ClipboardService
  ) {}

  copyQuery() {
    this.clipboardApi.copyFromContent(this.data.body.toString());
  }
}
