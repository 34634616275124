import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tooltip-image',
  templateUrl: './tooltip-image.component.html',
  styleUrls: ['./tooltip-image.component.scss']
})
export class TooltipImageComponent {
  @Input() url: string;
  @Input() tooltip: string;

  isLoading = true;

  /**
   * Hide the loading spinner when the image is loaded
   */
  hideLoader() {
    this.isLoading = false;
  }
}
