export enum OrientationType {
  Unknown,
  Portrait,
  Landscape
}

export enum DeviceType {
  Unknown,
  Tablet,
  Handset, //Mobile
  Web
}

export enum ScreenSizeType {
  Unknown,
  XSmall,
  Small,
  Medium,
  Large,
  XLarge
}

export enum BreakpointType {
  Unknown,
  Tablet,
  Handset, //Mobile
  Web,
  HandsetPortrait,
  HandsetLandscape,

  TabletPortrait,
  TabletLandscape,

  WebPortrait,
  WebLandscape
}
