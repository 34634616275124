<div class="image-div">
  <mat-spinner class="image-spinner" *ngIf="isLoading" diameter="40"></mat-spinner>
  <img
    class="image-class"
    [src]="url"
    [alt]="tooltip"
    [hidden]="isLoading"
    (load)="hideLoader()"
    (error)="hideLoader()"
  />
  <div class="hover-text" *ngIf="!isLoading">{{ tooltip }}</div>
</div>
