import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { GeneralSearchConstants, TimeFormats } from '@core/grammar/SmartModel';
import { PropertiesService } from '@core/services/properties/properties.service';
import { QueryCriteria } from '@shared/model/search-form';

/**
 * Helper service for anything related to the queries creation.
 *
 * @export
 * @class QueriesHelperService
 */
@Injectable({
  providedIn: 'root'
})
export class QueriesHelperService {
  /**
   * constructor
   * @param datePipe
   * @param propsService
   */
  constructor(
    private datePipe: DatePipe,
    private propsService: PropertiesService
  ) {}

  /**
   * Creates a query condition.
   *
   * @param {string} key
   * @param {string} value
   * @returns {string}
   * @memberof QueriesHelperService
   */
  createCondition(key: string, value: string, operator: string): string {
    /* istanbul ignore else */
    if (value) {
      const op = operator ? operator : '=';
      /* istanbul ignore else */
      if (value.includes("'")) {
        value = value.replace("'", "''");
      }
      const encodedValue = op === 'like' || op === 'ilike' ? '%' + value + '%' : value;
      return `a.${key} ${op} '${encodedValue}'`;
    }
    return undefined;
  }

  /**
   * Creates custom search criteria for proposals.
   * @param key
   * @param value
   */
  createDefaultTextCriteria(key: string, value: any): string | undefined {
    if (value[0] == '"' && value[value.length - 1] == '"') {
      // create exact search
      return this.createCondition(key, value.replace(/\"/g, ''), '=');
    }
    if (value.includes('*')) {
      return this.createCondition(key, value.replace(/\*/g, '%'), 'ilike');
    }
    if (value.includes('?')) {
      return this.createCondition(key, value.replace(/\?/g, '_'), 'ilike');
    }
    return this.createCondition(key, value, 'ilike');

    return '';
  }

  /**
   * Creates a date range query.
   *
   * @param {*} start
   * @param {string} startCol
   * @param {*} end
   * @param {string} endCol
   * @memberof QueriesHelperService
   */
  createDateRangeCondition(start: any, startCol: string, end?: any, endCol?: string) {
    // start
    if (start && !end) {
      const startDate = this.datePipe.transform(start, TimeFormats.dateFormatDataBase);
      const startCond = `(${startCol}>='${startDate} ${TimeFormats.startDayHour}' and \
                         ${startCol}<='${startDate} ${TimeFormats.endDayHour}')`;
      const endCond = `(${endCol}>='${startDate} ${TimeFormats.startDayHour}' and \
                        ${endCol}<='${startDate} ${TimeFormats.endDayHour}')`;
      return `${startCond} or ${endCond}`;
    } else if (start && end) {
      const startDate = this.datePipe.transform(start, TimeFormats.dateTimeFormatDataBase);
      const endDate = this.datePipe.transform(end, TimeFormats.dateTimeFormatDataBase);
      return `${startCol}<= '${endDate}' and ${endCol} >= '${startDate}'`;
    }
  }

  /**
   *Creates a geometrical condition.
   *
   * @param {any} ra
   * @param {any} dec
   * @param {any} radius
   * @returns {string}
   * @memberof QueriesHelperService
   */
  createGeometricalCondition(ra: any, dec: any, radius: any, target?: string): QueryCriteria {
    // Geometry condition
    const geomCond = target ? `target=${target}` : `ra/dec=${ra.toFixed(2)}/${dec.toFixed(2)}`;

    /* istanbul ignore else */
    if (!ra || !dec || ra === '*' || dec === '*') {
      return;
    }
    const finalRadius = radius ? radius : GeneralSearchConstants.defRadius;

    return {
      criteria: `1=CONTAINS(POINT('ICRS',a.ra,a.dec),CIRCLE('ICRS',${ra},${dec},${finalRadius}))`,
      readableCriteria: `${geomCond},radius=${radius}`
    } as QueryCriteria;
  }

  /**
   * Creates the target resolver query.
   *
   * @param {string} target
   * @returns {string}
   * @memberof QueriesHelperService
   */
  createTargetResolverUrl(target: string): string {
    const totalUrl = this.getTargetResolverUrl();
    // Remove ""
    const cleanTarget = encodeURIComponent(target.replace(/\"/g, ''));
    return totalUrl.replace('@target@', cleanTarget);
  }

  /**
   * Builds the URL Base for tap queries based on the environment.
   *
   * @private
   * @returns {string}
   * @memberof TapService
   */
  getBaseMetadataQueryUrl(): string {
    const url = this.propsService.getProperty('apiTapUrl');
    const basicParams = this.propsService.getProperty('basicParamsTapQuery');
    const tapClient = this.propsService.getProperty('tapClientParameter');
    return `${url}?${basicParams}${tapClient}`;
  }

  /**
   * Builds the URL Base for tap queries based on the environment.
   *
   * @private
   * @returns {string}
   * @memberof TapService
   */
  getBaseDataDownloadUrl(): string {
    const url = this.propsService.getProperty('apiUrl');
    const basicParams = this.propsService.getProperty('basicParamsDataDownload');
    return `${url}/${basicParams}`;
  }

  /**
   * Builds the URL Base for retrieving planes for the spectra viewer.
   *
   * @private
   * @returns {string}
   * @memberof TapService
   */
  getBaseSpectraUrl(): string {
    const url = this.propsService.getProperty('apiUrl');
    const basicParams = this.propsService.getProperty('basicSpectraUrl');
    return `${url}/${basicParams}`;
  }

  /**
   * Builds the URL Base for tap queries based on the environment.
   *
   * @private
   * @returns {string}
   * @memberof TapService
   */
  getBaseProductDownloadUrl(): string {
    const url = this.propsService.getProperty('apiSlServerUrl');
    const basicParams = this.propsService.getProperty('basicParamsProductDownload');
    return `${url}/${basicParams}`;
  }

  /**
   *
   * @returns
   */
  getBaseArtifactDownloadUrl(): string {
    const url = this.propsService.getProperty('apiSlServerUrl');
    const basicParams = this.propsService.getProperty('basicParamsArtifactDownload');
    return `${url}/${basicParams}`;
  }

  /**
   * Get url to download Jupiter Notebook
   * @returns
   */
  getIpynbDownloadUrl(): string {
    const url = this.propsService.getProperty('apiUrl');
    const basicParams = this.propsService.getProperty('ipynb');
    return `${url}/${basicParams}`;
  }

  /**
   * Creates a revolution range query.
   * @param selectedCollection
   */
  createCollectionCondition(selectedCollection: string[]): any {
    const conditions = [];
    for (const col of selectedCollection) {
      conditions.push("a.obs_type like '" + col + "%'");
    }
    return '(' + conditions.join(' or ') + ')';
  }

  /**
   * Creates a revolution range query.
   * @param intent
   */
  createIntentCondition(selectedIntent: string[]): any {
    const conditions = [];
    for (const col of selectedIntent) {
      conditions.push("a.intent = '" + col + "'");
    }
    return '(' + conditions.join(' or ') + ')';
  }

  /**
   * Creates a wavelength query.
   * @param selectedBand
   * @param waveMin
   * @param waveMax
   */
  createWavelengthConditionAndReadable(selectedBand: string, waveMin: number, waveMax: number): string[] {
    const wavelengthQueries = [];
    const wavelengthReadableCondition = [];
    if (selectedBand !== 'All') {
      wavelengthQueries.push("a.band_name = '" + selectedBand + "'");
      wavelengthReadableCondition.push('band=' + selectedBand);
    }
    if (waveMin) {
      wavelengthQueries.push('a.wave_min >= ' + waveMin.toString());
      wavelengthReadableCondition.push('wave min>=' + waveMin);
    }

    if (waveMax) {
      wavelengthQueries.push('a.wave_max <= ' + waveMax.toString());
      wavelengthReadableCondition.push('wave max<=' + waveMax);
    }

    return ['(' + wavelengthQueries.join(' and ') + ')', wavelengthReadableCondition.join(', ')];
  }

  /**
   * Builds the URL Base for target resolver.
   *
   * @private
   * @returns {string}
   * @memberof TapService
   */
  getTargetResolverUrl(): string {
    const url = this.propsService.getProperty('apiUrl');
    const basicParams = this.propsService.getProperty('paramsTargetResolver');
    const tapClient = this.propsService.getProperty('tapClientParameter');
    return `${url}/${basicParams}${tapClient}`;
  }
}
