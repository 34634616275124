import { HttpResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PropertiesService } from '@core/services/properties/properties.service';
import { QueriesHelperService } from '@core/services/queries/queries-helper.service';
import { TapService } from '@core/services/tap/tap.service';
import { NotificationService } from '@core/services/utils/notification.service';
import { ClipboardService } from 'ngx-clipboard';
import { MatomoTracker } from 'ngx-matomo';

export interface DialogData {
  header: string;
  body: any;
}

@Component({
  templateUrl: './query-dialog.component.html',
  styleUrls: ['./dialog-common.scss']
})
export class QueryDialogComponent {
  datalabsId: string;
  showDatalabs: boolean;

  constructor(
    protected ref: MatDialogRef<QueryDialogComponent>,
    private clipboardApi: ClipboardService,
    private tapService: TapService,
    private toastr: NotificationService,
    private queriesHelperService: QueriesHelperService,
    private propertiesService: PropertiesService,
    private matomoTracker: MatomoTracker,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.showDatalabs = this.propertiesService.getProperty('showDatalabs');
  }

  /***
   * Copy string to clipboard
   */
  copyString(inputString: string) {
    this.clipboardApi.copyFromContent(inputString);
    this.matomoTracker.trackEvent('eHST_Query', 'copy_command');
  }

  /**
   * Returns the string for CURL download
   * @returns
   */
  getCurlString() {
    return `curl -o file.vot "${this.getDownloadUrl()}"`;
  }

  /**
   *
   * @returns
   */
  getWgetString() {
    return `wget -O file.vot "${this.getDownloadUrl()}"`;
  }

  /**
   * Retrieve the url to download the votable
   * @returns
   */
  getDownloadUrl() {
    const encodedQuery = encodeURIComponent(this.data.body.replace('json', 'vot'));
    return `${this.queriesHelperService.getBaseMetadataQueryUrl()}&QUERY=${encodedQuery}`.replace('json', 'votable');
  }

  /**
   * Get Astroquery String
   * @returns
   */
  getAstroqueryString() {
    return `from astroquery.esa.hubble import ESAHubble
    esahubble = ESAHubble()
    query = "${this.data.body}"
    result = esahubble.query_tap(query)`;
  }

  /**
   * Create and download Jupiter NoteBook or show it in a Datalabs
   */
  getJupyterNotebook = (showInDatalabs: boolean) => {
    const query = this.getAstroqueryString();
    const httpOptions = {
      observe: 'response'
    };
    this.tapService
      .executeGenericPostQuery(
        this.queriesHelperService.getIpynbDownloadUrl(),
        {
          query: `${query}`,
          datalabs: showInDatalabs
        },
        httpOptions
      )
      .subscribe({
        next: (response: any) => {
          if (!showInDatalabs) {
            this.downloadDataFromTap(
              JSON.stringify(response.body),
              this.getFilename(response),
              'application/x-ipynb+json'
            );
            this.matomoTracker.trackEvent('eHST_Query', 'download_Jupyter_Notebook');
          } else {
            if (this.datalabsId) {
              let datalabsUrl = this.propertiesService.getProperty('datalabsFilenameUrl');
              const filename = this.getFilename(response);
              datalabsUrl = datalabsUrl.replace('datalabs_id', this.datalabsId).replace('notebook_filename', filename);
              window.open(datalabsUrl, '_blank');

              this.matomoTracker.trackEvent('eHST_Query', 'get_Jupyter_Notebook_existing_datalabs');
            } else {
              window.open(this.propertiesService.getProperty('datalabsUrl'), '_blank');
              this.matomoTracker.trackEvent('eHST_Query', 'get_Jupyter_Notebook_new_datalabs');
            }
          }
        },
        error: () => {
          this.toastr.error('Download failed, please try again later', 'Jupyter Notebook');
        }
      });
  };

  /**
   * Extracts the filename provided by the server
   * @param response
   * @returns
   */
  getFilename(response: HttpResponse<any>): string {
    return response.headers.get('Content-Disposition')?.split('filename=')[1];
  }

  /**
   * Download data from tap server, input data and type of data
   * @param data
   * @param type
   */
  downloadDataFromTap = (data: any, name: string, type: string) => {
    const blob = new Blob([data], { type: type });
    const a: any = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  close() {
    this.ref.close();
  }
}
