import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QueriesHelperService } from '@core/services/queries/queries-helper.service';
import { TapMetadata } from '@shared/model/tap.metadata';
import { goToExternalLink } from '@utils/utils';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

/**
 *  Collects ancillary methods to execute tap queries.
 *
 * @export
 * @class TapService
 */
@Injectable({
  providedIn: 'root'
})
export class TapService {
  /**
   * Service Constructor
   * @param http
   * @param queriesHelperService
   */
  constructor(
    private http: HttpClient,
    private queriesHelperService: QueriesHelperService
  ) {}

  /**
   * Executes the query sent in the input param.
   *
   * @private
   * @param {string} query
   * @returns {Observable<HttpResponse<object>>}
   * @memberof TapService
   */
  executeTapQuery(query: string): Observable<TapMetadata> {
    const encodedQuery = this.getFullTapQuery(query);
    return this.executeGenericGetQuery(encodedQuery) as Observable<TapMetadata>;
  }

  /**
   *
   * @param query
   * @returns
   */
  getFullTapQuery(query) {
    const encodedQuery = encodeURIComponent(query);
    return this.getBaseMetadataQueryUrl(encodedQuery);
  }

  /**
   * Download the results of a query with the required format
   * @param query
   * @param format
   * @param source
   */
  downloadQueryResults(query: string, format: string) {
    const url = this.getFullTapQuery(query).replace('FORMAT=json', 'FORMAT=' + format);
    goToExternalLink(url);
  }

  /**
   *
   * @param encodedQuery
   * @returns
   */
  getBaseMetadataQueryUrl = (encodedQuery) => {
    return `${this.queriesHelperService.getBaseMetadataQueryUrl()}&QUERY=${encodedQuery}`;
  };

  /**
   *
   * @param query
   * @returns
   */
  getTabulatorQuery(query) {
    let encodedQuery = query.replace(/%/g, '%25');
    encodedQuery = encodedQuery.replace(/'/g, '%27');
    encodedQuery = encodedQuery.replace('&', '%26');
    return this.getBaseMetadataQueryUrl(encodedQuery);
  }

  /**
   * Executes any url query.
   *
   * @param {string} urlQuery
   * @returns {Observable<object>}
   * @memberof TapService
   */
  executeGenericGetQuery(urlQuery: string, requestOptions: any = {}): Observable<any> {
    return this.http.get(urlQuery, requestOptions).pipe(retry(1), catchError(this.handleError));
  }

  /**
   * Executes any post query.
   *
   * @param {string} urlQuery
   * @memberof TapService
   */
  executeGenericPostQuery(url: any, body: any, requestOptions: object = {}): Observable<any> {
    return this.http.post(url, body, requestOptions).pipe(retry(1), catchError(this.handleError));
  }

  /**
   * Error inspection, interpretation, and resolution is something you
   * want to do in the service, not in the component.
   * @private
   * @param {HttpErrorResponse} error
   * @returns
   * @memberof TapService
   */
  private handleError(error: HttpErrorResponse) {
    const blb = new Blob([error.error], { type: 'text/plain' });

    const reader: FileReader = new FileReader();
    const obs = new Observable((observer: any) => {
      reader.onloadend = () => {
        const messageObject = reader.result;
        observer.error({
          error: {
            message: messageObject
          },
          message: messageObject,
          status: error.status
        });
        observer.complete();
      };
    });
    reader.readAsText(blb);
    return obs;
  }
}
