import { NgModule, Optional, SkipSelf } from '@angular/core';
import { PropsServiceProvider } from '@core/services/properties/properties.service';
import { SharedModule } from '@shared/shared.module';
import { AngularMaterialModule } from 'app/angular-material/angular-material-module';
import { httpInterceptorProviders } from './interceptors';
import { ThemeModule } from './theme/theme.module';

@NgModule({
  declarations: [],
  imports: [SharedModule, AngularMaterialModule, ThemeModule.forRoot()],
  providers: [httpInterceptorProviders, PropsServiceProvider]
})

/** This module should be really singleton */
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
