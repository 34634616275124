import { Component, Input } from '@angular/core';
import { ResponsiveService } from '@core/services/responsive/responsive.service';

@Component({
  selector: 'app-toggle-panels',
  template: `
    <div class="pb-1">
      <button mat-button (click)="toggleAllExpansionPanels(expand)" class="w-100">
        <mat-icon>{{ expand ? 'expand_more' : 'expand_less' }}</mat-icon>
        {{ !responsiveService.isSmallOrMediumScreen() ? (expand ? 'Expand All' : 'Collapse All') : '' }}
      </button>
    </div>
  `
})

/**
 * Toggle expasions panels. Expand and Collapse
 */
export class TogglePanelsComponent {
  @Input() expansionPanels = [];

  // Toggle Expand
  expand = true;

  /**
   * Constructor
   */
  constructor(public responsiveService: ResponsiveService) {
    //empty
  }

  /**
   * Collapse/Expand All Expansion panels
   * @param expand
   */
  toggleAllExpansionPanels = (expand: boolean): void => {
    // Toggle panels
    this.expansionPanels.forEach((panel) => {
      expand ? panel.open() : panel.close();
    });
    // Toggle Value
    this.expand = !this.expand;
  };
}
