import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';

import { BreakpointType, DeviceType, OrientationType, ScreenSizeType } from './responsive.enum';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {
  embeddedVersion = false;
  private _deviceType = DeviceType.Unknown;
  private _screenSize = ScreenSizeType.Unknown;
  private _showSearchPanel = true;
  private _showSearchLabels = true;
  private _orientation = OrientationType.Unknown;
  private readonly screenSizeBreakpoints = new Map([
    [Breakpoints.XSmall, ScreenSizeType.XSmall],
    [Breakpoints.Small, ScreenSizeType.Small],
    [Breakpoints.Medium, ScreenSizeType.Medium],
    [Breakpoints.Large, ScreenSizeType.Large],
    [Breakpoints.XLarge, ScreenSizeType.XLarge]
  ]);
  private orientationTypes = Object.keys(OrientationType).map((key) => key);
  private deviceTypes = Object.keys(DeviceType).map((key) => key);
  private _gridCols = 2;

  /**
   * constructor
   * @param breakpointObserver
   */
  constructor(breakpointObserver: BreakpointObserver) {
    // Detect the current size when load the aplication

    // Check the current screen Size
    this.checkScreenSize(breakpointObserver);
    //Check devive type and orientation
    this.checkDeviceTypeAndOrientation(breakpointObserver);
  }

  /**
   * Get ScreenSize
   */

  public getScreenSize(): ScreenSizeType {
    return this._screenSize;
  }

  /**
   * toggle search panel
   * @param value
   */
  public toggleSearchPanel(): void {
    this._showSearchPanel = !this._showSearchPanel;
  }
  /**
   * Search show search panel value
   * @param value
   */
  public setShowSearchPanel(value: boolean): void {
    this._showSearchPanel = value;
  }
  /**
   * Get show panel value
   * @returns
   */
  public showSearchPanel(): boolean {
    return this._showSearchPanel;
  }

  /**
   * Set show labels value
   * @param value
   */
  public setShowSearchLabels(value: boolean): void {
    this._showSearchLabels = value;
  }

  /**
   * Get show labels value
   * @returns
   */
  public showSearchLabels(): boolean {
    return this._showSearchLabels;
  }

  /**
   * Get if the screen size is the specified one
   */
  public verifyScreenSize(sc: ScreenSizeType) {
    return this._screenSize === sc;
  }

  /**
   * Condition to show or not he labels text
   * @returns
   */
  public checkShowView = () => {
    if (this.verifyScreenSize(ScreenSizeType.XLarge) || this.verifyScreenSize(ScreenSizeType.Large)) {
      return true;
    }
    return false;
  };

  /**
   * Check Screen Size Observer
   * @param breakpointObserver
   */
  private checkScreenSize(breakpointObserver: BreakpointObserver): void {
    breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .subscribe((result: any) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this._screenSize = this.screenSizeBreakpoints.get(query) ?? ScreenSizeType.Unknown;
            // Show labels in more than Large Size
            this.setShowSearchLabels(this.checkShowView());

            // Set search panel
            this.setShowSearchPanel(this.verifyScreenSize(ScreenSizeType.Small));
          }
        }
      });
  }

  /**
   * Verifies the orientation
   * @returns
   */
  public checkOrientation(orientationType: OrientationType): boolean {
    return this._orientation === orientationType;
  }

  /**
   * Verifies the device
   */
  public checkDevice(device: DeviceType): boolean {
    return this._deviceType === device;
  }

  /**
   * Get deviceType
   * @returns
   */

  public getDeviceType(): DeviceType {
    return this._deviceType;
  }

  /**
   * Get orientation
   * @returns
   */

  public getOrientation(): OrientationType {
    return this._orientation;
  }

  /**
   * checkDeviceTypeAndOrientation
   * @param breakpointObserver
   */
  private checkDeviceTypeAndOrientation(breakpointObserver: BreakpointObserver): void {
    breakpointObserver
      .observe([
        Breakpoints.HandsetLandscape,
        Breakpoints.HandsetPortrait,
        Breakpoints.WebLandscape,
        Breakpoints.WebPortrait,
        Breakpoints.TabletLandscape,
        Breakpoints.TabletPortrait,
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge
      ])
      .subscribe((result: any) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            const type =
              Object.keys(Breakpoints)[Object.values(Breakpoints).indexOf(query)] ??
              (query as keyof typeof BreakpointType);
            this.checkOrientationAndDeviceTypes(type);
            this.checkGridColumns(result);
          }
        }
      });
  }

  /** */
  checkOrientationAndDeviceTypes(type: string) {
    this.orientationTypes.forEach((element) => {
      // console.log(element as keyof typeof OrientationType);
      if (type.indexOf(element) !== -1) {
        this._orientation = OrientationType[element];
      }
    });

    this.deviceTypes.forEach((element) => {
      if (String(type).indexOf(BreakpointObserver[element as keyof typeof DeviceType]) !== -1)
        this._deviceType = DeviceType[element as keyof typeof DeviceType];
    });
  }

  /**
   * Returns the initial size of the search panel based on the screen size
   */
  getSearchPanelSize(): number {
    return this.showSearchPanel() ? 0 : this.verifyScreenSize(ScreenSizeType.XLarge) ? 20 : 25;
  }

  /**
   * Returns the initial size of the result panel based on the screen size
   */
  getResultsPanelSize() {
    return this.showSearchPanel() ? 100 : this.verifyScreenSize(ScreenSizeType.XLarge) ? 80 : 75;
  }

  /**
   * Set if header and footer should be removed for the gallery in an iframe
   * @param url
   */
  showEmbeddedGallery(url: string) {
    this.embeddedVersion = url.includes('embedded');
  }

  /**
   * Check if header and footer should be removed for the gallery in an iframe
   * @returns
   */
  isEmbeddedGallery(): boolean {
    return this.embeddedVersion;
  }

  /**
   * Retrieve the number of columns according to the screen size
   * @returns
   */
  getGridColumns(): number {
    return this._gridCols;
  }

  /**
   * Return 2 columns for wide screens, 1 column for small screens
   * @param result
   */
  checkGridColumns(result: any) {
    this._gridCols = result.breakpoints[Breakpoints.XSmall] || result.breakpoints[Breakpoints.Small] ? 1 : 2;
  }

  /**
   * Check if it is a small screen
   * @returns
   */
  isSmallScreen(): boolean {
    return this.verifyScreenSize(ScreenSizeType.Small) || this.verifyScreenSize(ScreenSizeType.XSmall);
  }

  /**
   * Check if the screen is small or medium
   * @returns
   */
  isSmallOrMediumScreen(): boolean {
    return this.isSmallScreen() || this.verifyScreenSize(ScreenSizeType.Medium);
  }
}
