import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TableDialogService } from '@core/services/table-dialog.service';

/**
 * Data dialog Interface
 */
export interface DialogData {
  tableHeaders: any;
  title: any;
  headerButton: boolean;
  subtitle: any;
  footer: any;
  doi: any;
  publications: any;
  hyperlink: boolean;
  url: any;
  searchButton: boolean;
  tableStartIndex: any;
  proposal: string[];
}

/**
 *
 */
@Component({
  templateUrl: './table-dialog.component.html',
  styleUrls: ['./table-dialog.component.scss']
})
export class TableDialogComponent {
  displayedColumns: string[] = ['title', 'authors', 'hyperlink'];
  constructor(
    protected ref: MatDialogRef<TableDialogComponent>,
    private tableDialogService: TableDialogService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  /**
   * Set dialog header menssage
   */
  setHeaderMessage() {
    this.tableDialogService.setHeaderMessage(this.data.proposal);
  }

  /**
   * Set Dialog Message
   * @param value0
   * @param value1
   */
  setDialogMessage(value0: any, value1: any) {
    this.tableDialogService.setMessage([value0, value1]);
    this.close();
  }

  /**
   * Remove Last comma
   * @param text
   * @returns
   */
  removeLastComma(text: string) {
    if (text.endsWith(', ')) {
      return text.slice(0, -2);
    }
    return text;
  }

  /**
   * Get Doi Url
   * @returns
   */
  getDOIUrl() {
    return 'https://doi.org/' + this.data.doi;
  }

  /**
   * Close dialog
   */
  close() {
    this.ref.close();
  }
}
