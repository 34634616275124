import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  header: string;
  body: any;
}

@Component({
  template: `
    <span mat-dialog-title>{{ data.header }}</span>
    <mat-dialog-content>
      {{ data.body }}
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-raised-button class="button-esa" matTooltip="Confirm request" (click)="ref.close(true)">
        Confirm
      </button>

      <button
        mat-raised-button
        class="button-esa"
        color="primary"
        matTooltip="Dismiss request"
        (click)="ref.close(false)"
      >
        Cancel
      </button>
    </mat-dialog-actions>
  `,
  styleUrls: ['./dialog-common.scss']
})
export class ConfirmDialogComponent {
  constructor(
    protected ref: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}
}
