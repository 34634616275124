<span mat-dialog-title
  >{{ data.header }}
  <button [autofocus]="false" mat-icon-button class="close-button" (click)="close()" [mat-dialog-close]="true">
    <mat-icon color="warn">close</mat-icon>
  </button></span
>
<mat-dialog-content class="proposal-table">
  <div class="query-dialog-content" fxLayout="column" fxLayoutGap="10px">
    <div fxLayout="column" class="command-row">
      <h4>
        Query
        <button
          class="me-2 button-icon-esa"
          mat-raised-button
          (click)="copyString(data.body.toString())"
          matTooltip="Copy query to clipboard"
        >
          <mat-icon fontSet="material-icons-outlined">content_copy</mat-icon>
          <span>Copy Query</span>
        </button>
      </h4>
      {{ data.body }}
    </div>

    <div fxLayout="column" class="command-row">
      <h4>
        CURL
        <button
          class="me-2 button-icon-esa"
          mat-raised-button
          (click)="copyString(this.getCurlString())"
          matTooltip="Copy CURL to clipboard"
        >
          <mat-icon fontSet="material-icons-outlined"> content_copy </mat-icon>
          <span>Copy CURL</span>
        </button>
      </h4>
      {{ getCurlString() }}
    </div>
    <div fxLayout="column" class="command-row">
      <h4>
        WGET
        <button
          class="me-2 button-icon-esa"
          mat-raised-button
          (click)="copyString(this.getWgetString())"
          matTooltip="Copy WGET to clipboard"
        >
          <mat-icon fontSet="material-icons-outlined"> content_copy </mat-icon>
          <span>Copy WGET</span>
        </button>
      </h4>
      {{ getWgetString() }}
    </div>
    <div fxLayout="column" class="command-row">
      <h4>
        Astroquery
        <button
          class="me-2 button-icon-esa"
          mat-raised-button
          (click)="copyString(this.getAstroqueryString())"
          matTooltip="Copy astroquery commands to clipboard"
        >
          <mat-icon fontSet="material-icons-outlined"> content_copy </mat-icon>
          <span>Copy Astroquery</span>
        </button>
      </h4>
      <div class="query">{{ getAstroqueryString() }}</div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div fxLayout="row" fxLayoutGap="2px">
    <button
      class="me-2 button-icon-esa"
      mat-raised-button
      (click)="this.getJupyterNotebook(false)"
      matTooltip="Download Jupyter Notebook"
    >
      <mat-icon fontSet="material-icons-outlined"> download </mat-icon>
      <span>Download Jupyter Notebook</span>
    </button>

    <button *ngIf="showDatalabs" class="me-2 button-icon-esa" mat-raised-button [matMenuTriggerFor]="menu">
      <mat-icon fontSet="material-icons-outlined"> cloud_upload </mat-icon>ESA Datalabs
    </button>
  </div>
  <mat-menu #menu="matMenu">
    <div class="datalabs-popup">
      <button
        class="w-100 button-esa"
        color="basic"
        mat-raised-button
        (click)="this.getJupyterNotebook(true)"
        matTooltip="Open in ESA Datalabs"
      >
        <mat-icon fontSet="material-icons-outlined"> cloud_download </mat-icon>
        <span>Open in ESA Datalabs</span>
      </button>
      <mat-form-field class="w-100 datalabs-field" (click)="$event.stopPropagation()">
        <mat-label>(Optional) Existing Datalabs ID</mat-label>
        <input class="w-100" matInput type="text" [(ngModel)]="datalabsId" />
      </mat-form-field>
      <mat-hint
        >If you have a running Datalabs, use it here!
        <div style="font-size: 12px" (click)="$event.stopPropagation()">
          E.g. https://datalabs.esa.int/datalab-viewer/<u style="color: red">43fab15b67714881</u>
        </div></mat-hint
      >
    </div>
  </mat-menu>
</mat-dialog-actions>
